import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType, DealStatusEnumType } from '__generated__/graphql';
import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import theme from './theme.module.scss';

const CREATE_POST_MUTATION = gql(`
  mutation CreatePostForDeal($title: String!, $message: String!, $syndicateId: ID!, $dealId: ID, $targetClosingStatuses: [ClosingStatusEnumType!], $attachments: [Upload]) {
    createPost(title: $title, message: $message, syndicateId: $syndicateId, dealId: $dealId, targetClosingStatuses: $targetClosingStatuses, attachments: $attachments) {
      id
      status
      title
      message
      createdAt
      publishedAt
      attachments {
        url
        name
      }
      deal {
        companyInformation {
          company {
            name
          }
        }
      }
      sender {
        id
        name
      }
      syndicate {
        id
        name
        image
      }
    }
  }
`);

const CreatePost: FC<{ dealStatus: DealStatusEnumType }> = ({ dealStatus }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const [tempError, showTempError] = useState(false);
  const { syndicateId, dealId } = useParams<{ syndicateId: string; dealId: string }>() as {
    syndicateId: string;
    dealId: string;
  };

  const [createPost, { loading, error }] = useMutation(CREATE_POST_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'DealType',
          id: dealId,
        }),
        fields: {
          posts(existingPostsRef) {
            const newPostNodeRef = cache.writeFragment({
              data: data.createPost,
              fragment: gql(`
                fragment NewPost on PostType {
                  id
                  status
                  title
                  message
                  createdAt
                  publishedAt
                  deal {
                    companyInformation {
                      company {
                        name
                      }
                    }
                  }
                  sender {
                    id
                    name
                  }
                  syndicate {
                    id
                    name
                    image
                  }
                }
              `),
            });

            const updatedPostsRef = {
              nodes: [newPostNodeRef, ...existingPostsRef.nodes],
              pageInfo: { ...existingPostsRef.pageInfo, cursor: data.createPost?.id },
            };

            return updatedPostsRef;
          },
        },
      });
    },
  });

  return (
    <div className={theme.postContainer}>
      <div className="flex justify-end">
        <Button onClick={() => setOpen(true)}>Create Post</Button>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel className="sm:!w-screen sm:!max-w-3xl">
          <DialogTitle>Create a Post</DialogTitle>
          <DialogSubTitle>Send a message to the investors in this deal</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data => {
              showTempError(false);
              if (
                data.targetAudience === 'CLOSINGS' &&
                (!data.targetClosingStatuses || data.targetClosingStatuses.length === 0)
              ) {
                showTempError(true);
                return;
              }
              createPost({
                variables: {
                  syndicateId,
                  title: data.title,
                  message: data.content,
                  dealId,
                  targetClosingStatuses:
                    data.targetAudience === 'SYNDICATE_USERS'
                      ? undefined
                      : data.targetClosingStatuses,
                  attachments: data.files ? data.files : undefined,
                },
              }).then(() => {
                setOpen(false);
              });
            }}
            onCancel={() => setOpen(false)}
            buttonRef={buttonRef}
          >
            <FormInput
              fullWidth
              type="radio-group"
              fieldName="targetAudience"
              label="Who should see this post?"
              defaultValue={'SYNDICATE_USERS'}
              options={[
                { label: 'All LPs of my Syndicate', value: 'SYNDICATE_USERS' },
                { label: 'Only the investors in this deal', value: 'CLOSINGS' },
              ]}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="checkbox-group"
              fieldName="targetClosingStatuses"
              label="Choose investors by stage of investment"
              defaultValue={
                dealStatus === DealStatusEnumType.Exited || dealStatus === DealStatusEnumType.Wired
                  ? [ClosingStatusEnumType.Active]
                  : [ClosingStatusEnumType.Committed, ClosingStatusEnumType.Transferred]
              }
              compact
              options={
                dealStatus === DealStatusEnumType.Exited || dealStatus === DealStatusEnumType.Wired
                  ? [{ label: 'Active', value: ClosingStatusEnumType.Active }]
                  : [
                      { label: 'Committed', value: ClosingStatusEnumType.Committed },
                      { label: 'Transferred', value: ClosingStatusEnumType.Transferred },
                    ]
              }
              conditionsToShow={{
                matches: [
                  {
                    field: 'targetAudience',
                    condition: '===',
                    value: 'CLOSINGS',
                  },
                ],
              }}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="text"
              fieldName="title"
              label="Title"
              defaultValue={''}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="custom"
              fieldName="content"
              label="Content"
              defaultValue={''}
              customInput={EditorFormInput}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="upload"
              fieldName="files"
              label="Attachments"
              defaultValue={''}
            />
          </FormPanel>
          {tempError && (
            <p className="text-red-500">
              If you want to target the investors in this deal, please select at least one stage of
              investment
            </p>
          )}
          <DialogActions className="sm:grid-cols-2">
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Save Draft
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </div>
  );
};

export default CreatePost;
