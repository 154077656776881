import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ClosingStatusEnumType,
  DealStatusEnumType,
  PostStatusEnumType,
} from '__generated__/graphql';
import dayjs from 'dayjs';
import { useState } from 'react';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import constants from 'utils/constants';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import PublishPostDialogButton from './PublishPostDialogButton';

const GET_POST_QUERY = gql(`
  query GetPostInDealDashboard($id: ID!) {
    post(id: $id) {
      id
      status
      title
      message
      attachments {
        url
        name
      }
      sender {
        name
      }
      syndicate {
        id
        name
        image
      }
      deal {
        id
      }
      targetClosingStatuses
      createdAt
      publishedAt
    }
  }
`);

const UPDATE_POST_MUTATION = gql(`
  mutation UpdatePostOnDeal($id: ID!, $title: String!, $message: String!, $dealId: ID, $targetClosingStatuses: [ClosingStatusEnumType!]) {
    updatePost(id: $id, title: $title, message: $message, dealId: $dealId, targetClosingStatuses: $targetClosingStatuses) {
      id
      status
      title
      message
      deal {
        id
        companyInformation {
          id
          company {
            name
          }
        }
      }
      createdAt
      targetClosingStatuses
    }
  }
`);

const REMOVE_POST = gql(`
  mutation RemovePostFromDeal($id: ID!) {
    removePost(id: $id) {
      id
      status
    }
  }
`);

const PostSlideOver = ({
  open = false,
  postId,
  onClose,
  dealStatus,
}: {
  open: boolean;
  postId: string;
  onClose: () => void;
  dealStatus: DealStatusEnumType;
}) => {
  const [targetAudienceError, showTargetAudienceError] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(false);
  const { data, error, loading } = useQuery(GET_POST_QUERY, {
    variables: {
      id: postId,
    },
    skip: !open,
  });

  const [updatePost, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_POST_MUTATION,
    {
      refetchQueries: ['GetPostInDealDashboard', 'GetPostForPublish'],
    }
  );

  const [removePost, { loading: removeLoading, error: removeError }] = useMutation(REMOVE_POST, {
    update(cache) {
      cache.modify({
        id: cache.identify({
          __typename: 'DealType',
          id: data?.post.deal?.id,
        }),
        fields: {
          posts(existingPostsRef, { readField }) {
            return {
              ...existingPostsRef,
              nodes: existingPostsRef.nodes.filter(
                (postRef: any) => postId !== readField('id', postRef)
              ),
            };
          },
        },
      });
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} />;

    const post = data.post;

    const { label, color } = statusEnumToReadable(post.status);

    return (
      <>
        <div className="flex flex-col bg-gray-100 rounded-lg">
          <div className="flex flex-row p-2 justify-between items-center">
            <div className="flex flex-row p-2 space-x-4">
              <img
                src={post.syndicate.image ?? constants.ALT_AVATAR_URL}
                style={{
                  width: '50px',
                  height: '50px',
                  border: '0.5px solid #aaa',
                  borderRadius: '1000px',
                }}
                alt="Logo"
              />
              <div>
                <p className="font-bold">{post.syndicate.name}</p>
                <p className="text-sm">{post.sender.name}</p>
              </div>
            </div>
          </div>
        </div>
        <Badge label={label} color={color} />
        <p className="text-sm text-gray-500 !mt-1">
          {post.status === PostStatusEnumType.Published && post.publishedAt ? (
            <>
              Published at {dayjs(post.publishedAt).format('MMMM DD, YYYY hh:mm A')}
              <br />
            </>
          ) : (
            <>
              Created at {dayjs(post.createdAt).format('MMMM DD, YYYY hh:mm A')}
              <br />
            </>
          )}
        </p>
        <FormPanelWithReadMode
          loading={updateLoading}
          error={updateError}
          isInReadOnlyMode={readOnlyMode => setReadOnlyMode(readOnlyMode)}
          onSubmit={data => {
            showTargetAudienceError(false);
            if (
              data.targetAudience === 'CLOSINGS' &&
              (!data.targetClosingStatuses || data.targetClosingStatuses.length === 0)
            ) {
              showTargetAudienceError(true);
              return;
            }
            updatePost({
              variables: {
                id: postId,
                title: data.title,
                message: data.content,
                dealId: post.deal ? post.deal.id : undefined,
                targetClosingStatuses:
                  data.targetAudience === 'SYNDICATE_USERS'
                    ? undefined
                    : data.targetClosingStatuses,
              },
            });
          }}
          submitButtonLabel="Save"
          disableEdit={post.status !== PostStatusEnumType.Draft}
        >
          {dayjs(post.createdAt)
            .startOf('day')
            .isAfter(dayjs('2024-09-11T00:00:00+05:30').startOf('day')) ? (
            <>
              <FormInput
                fullWidth
                type="radio-group"
                fieldName="targetAudience"
                label="Who should see this post?"
                defaultValue={
                  post.targetClosingStatuses && post.targetClosingStatuses.length > 0
                    ? 'CLOSINGS'
                    : 'SYNDICATE_USERS'
                }
                options={[
                  { label: 'All LPs of my Syndicate', value: 'SYNDICATE_USERS' },
                  { label: 'Only the investors in this deal', value: 'CLOSINGS' },
                ]}
                validators={{
                  required: true,
                }}
              />
              <FormInput
                fullWidth
                type="checkbox-group"
                fieldName="targetClosingStatuses"
                label="Choose investors by stage of investment"
                defaultValue={
                  post.targetClosingStatuses ||
                  (dealStatus === DealStatusEnumType.Exited ||
                  dealStatus === DealStatusEnumType.Wired
                    ? [ClosingStatusEnumType.Active]
                    : [ClosingStatusEnumType.Committed, ClosingStatusEnumType.Transferred])
                }
                compact
                options={
                  dealStatus === DealStatusEnumType.Exited ||
                  dealStatus === DealStatusEnumType.Wired
                    ? [{ label: 'Active', value: ClosingStatusEnumType.Active }]
                    : [
                        { label: 'Committed', value: ClosingStatusEnumType.Committed },
                        { label: 'Transferred', value: ClosingStatusEnumType.Transferred },
                      ]
                }
                conditionsToShow={{
                  matches: [
                    {
                      field: 'targetAudience',
                      condition: '===',
                      value: 'CLOSINGS',
                    },
                  ],
                }}
                validators={{
                  required: true,
                }}
              />
            </>
          ) : (
            <></>
          )}
          <FormInput
            fullWidth
            type="text"
            fieldName="title"
            label="Title"
            defaultValue={post.title}
            validators={{
              required: true,
            }}
          />
          <FormInput
            fullWidth
            type="custom"
            fieldName="content"
            label="Content"
            defaultValue={post.message}
            customInput={EditorFormInput}
            validators={{
              required: true,
            }}
          />
        </FormPanelWithReadMode>
        {post.attachments && post.attachments.length > 0 && (
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-900">Attachments</h3>
            <ul className="mt-2 divide-y divide-gray-100 rounded-md border border-gray-200">
              {post.attachments.map(attachment => (
                <li key={attachment.url} className="flex items-center justify-between py-2 px-4">
                  <span className="truncate text-sm text-gray-500">{attachment.name}</span>
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-blue-600 hover:text-blue-500"
                  >
                    View
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {targetAudienceError ? (
          <p className="text-red-500">
            If you want to target the investors in this deal, please select at least one stage of
            investment
          </p>
        ) : null}
        {removeError ? <ErrorMessage error={removeError} /> : null}
        {post.status === PostStatusEnumType.Draft && !readOnlyMode ? (
          <div className="grid grid-cols-2 space-x-4 !mt-8">
            <PublishPostDialogButton id={postId} />
            <Button
              loading={removeLoading}
              variant="secondary"
              className="!text-red-500"
              onClick={() => {
                removePost({ variables: { id: postId } }).then(() => onClose());
              }}
            >
              Remove
            </Button>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <SlideOver open={open} onClose={onClose}>
      {renderContent()}
    </SlideOver>
  );
};

export default PostSlideOver;
