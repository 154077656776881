import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const INVESTMENTS_QUERY = gql(`
  query fetchInvestmentsByInvestmentId($id: ID!) {
    investmentEntity(id: $id) {
      id
      name
      closings(filters: { statuses: [ACTIVE, EXITED] }) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                name
                image
              }
            }
          }
          amount
          status
          wiredAt
          statistics {
            unrealisedValue
            multipleOnInvestment
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      fundClosings(filters: { statuses: [ACTIVE, EXITED] }) {
        nodes {
          id
          fund {
            id
            image
            name
            statistics {
              multipleOnInvestment
            }
          }
          amount
          status
          wiredAt
        }
      }
    }
  }
`);

const InvestmentEntityInvestments: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(INVESTMENTS_QUERY, {
    variables: {
      limit: 50,
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data.investmentEntity.closings.nodes;
  const pageInfo = data.investmentEntity.closings.pageInfo;

  let fundClosings = data.investmentEntity.fundClosings.nodes;
  fundClosings = fundClosings.map(fundClosing => ({
    ...fundClosing,
    unrealisedValue: fundClosing.fund.statistics.multipleOnInvestment
      ? fundClosing.fund.statistics.multipleOnInvestment * fundClosing.amount
      : '-',
  }));

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">Investments</h3>
      <DataTable
        data={closings}
        columns={[
          {
            label: 'Company',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Invested',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Invest Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          {
            label: 'Net Value',
            fieldName: 'statistics.unrealisedValue',
            type: 'CURRENCY',
          },
          {
            label: 'Multiple',
            fieldName: 'statistics.multipleOnInvestment',
            type: 'MULTIPLE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      <h3 className="text-lg font-medium leading-6 text-gray-900">Fund Investments</h3>
      <DataTable
        data={fundClosings}
        columns={[
          { label: 'Fund', fieldName: 'fund', type: 'IMAGE_WITH_NAME' },
          { label: 'Status', fieldName: 'status', type: 'STATUS' },
          { label: 'Invested', fieldName: 'amount', type: 'CURRENCY' },
          { label: 'Invest Date', fieldName: 'wiredAt', type: 'DATE' },
          { label: 'Net Value', fieldName: 'unrealisedValue', type: 'CURRENCY' },
          {
            label: 'Multiple',
            fieldName: 'fund.statistics.multipleOnInvestment',
            type: 'MULTIPLE',
          },
        ]}
      />
    </>
  );
};

export default InvestmentEntityInvestments;
