import { FC, useState } from 'react';

import { FormInput, FormPanel } from 'components/FormPanel';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

const CreateWishlistCompanyDialogButton: FC<{
  onSubmit: (company: { name: string; website?: string | null }) => void;
}> = ({ onSubmit }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="text" className="mt-1" onClick={() => setOpen(true)}>
        Or create a new one
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Submit a Company</DialogTitle>
          <FormPanel
            loading={false}
            error={false}
            onCancel={() => setOpen(false)}
            onSubmit={data => {
              onSubmit({
                name: data.name,
                website: data.website,
              });
              setOpen(false);
            }}
          >
            <FormInput
              fullWidth
              type="text"
              fieldName="name"
              label="Name *"
              defaultValue=""
              validators={{
                required: true,
                maxLength: 255,
                minLength: 3,
              }}
            />
            <FormInput
              fullWidth
              type="text"
              fieldName="website"
              label="Website (Optional)"
              defaultValue=""
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateWishlistCompanyDialogButton;
