import { useQuery } from '@apollo/client';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import {
  InvestmentEntityStatusEnumType,
  InvestmentEntityTypeEnumType,
} from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import investmentEntityTypeToReadable from 'utils/enums/investment-entity-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const INVESTMENT_ENTITIES_QUERY = gql(`
  query FetchInvestmentEntities {
    dashboard {
      id
      investmentEntities {
        id
        name
        status
        type
        createdAt
        updatedAt
        statistics {
          numberOfInvestments
        }
      }
    }
  }
`);

const InvestmentEntityCard: FC<{
  id: string;
  name: string;
  type: InvestmentEntityTypeEnumType;
  status: InvestmentEntityStatusEnumType;
  numberOfInvestments: number;
  createdAt: string;
  onClick: (id: string) => void;
}> = ({ id, name, type, status, numberOfInvestments, createdAt, onClick }) => {
  const { label: typeLabel } = investmentEntityTypeToReadable(type);
  const { label: statusLabel, color: statusColor } = statusEnumToReadable(status);

  return (
    <li
      key={id}
      className="relative flex justify-between gap-x-6 px-4 py-5 sm:px-6 hover:bg-gray-50 cursor-pointer"
      onClick={() => onClick(id)}
    >
      <div className="min-w-0 flex-auto">
        <p className="font-semibold leading-6 text-gray-700">
          <span className="absolute inset-x-0 -top-px bottom-0" />
          {name}
        </p>
        <p className="flex text-sm leading-5 text-indigo-400">{typeLabel}</p>
        <p className="text-xs leading-5 text-gray-500">
          {numberOfInvestments} investment{numberOfInvestments > 1 ? 's' : ''} made
        </p>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <p className="text-sm leading-6 text-gray-900">
            <Badge label={statusLabel} color={statusColor} />
          </p>
          <p className="mt-1 text-xs leading-5 text-gray-500">
            <time dateTime={dayjs(createdAt).format('DD MMMM YYYY')}>
              {dayjs(createdAt).format('DD MMMM YYYY')}
            </time>
          </p>
        </div>
        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
      </div>
    </li>
  );
};

const InvestmentEntitiesPage: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITIES_QUERY);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntities = data.dashboard.investmentEntities;

    return (
      <>
        <div className="sm:flex sm:items-center justify-start">
          <Button onClick={() => navigate('/invest/investment-entities/create')}>
            Create Investment Entity
          </Button>
        </div>
        <ul className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
          {investmentEntities.map(investmentEntity => (
            <InvestmentEntityCard
              key={investmentEntity.id}
              id={investmentEntity.id}
              name={investmentEntity.name}
              type={investmentEntity.type}
              status={investmentEntity.status}
              numberOfInvestments={investmentEntity.statistics.numberOfInvestments}
              createdAt={investmentEntity.createdAt!}
              onClick={id =>
                investmentEntity.status === 'DRAFT'
                  ? navigate(`/invest/investment-entities/create/${id}/basic-information`)
                  : navigate(`/invest/investment-entities/${id}`)
              }
            />
          ))}
        </ul>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Investment Entities"
        subTitle="Manage your investment accounts and see tax information"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default InvestmentEntitiesPage;
