import { gql, useMutation, useQuery } from '@apollo/client';
import { FC } from 'react';

import ErrorMessage from 'components/ErrorMessage';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

const USER_OISTER_INVITE_PERMISSION_QUERY = gql(`
  query CanRegisterForOisterInvite {
    dashboard {
      id
      user {
        id
        permissions {
          canRegisterOisterInviteInterest
        }
      }
    }
  }
`);

const REGISTER_INTEREST_MUTATION = gql(`
  mutation RegisterOisterInviteInterest {
    registerOisterInviteInterest {
      id
    }
  }
`);

const OptInToOisterInvites: FC = () => {
  const { loading, error, data, refetch } = useQuery(USER_OISTER_INVITE_PERMISSION_QUERY);

  const [registerOisterInviteInterest, { loading: mutationLoading }] = useMutation(
    REGISTER_INTEREST_MUTATION,
    {
      refetchQueries: ['CanRegisterForOisterInvite'],
    }
  );

  if (loading) return <LoadingIndicator />;
  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const canRegisterInterest = data.dashboard.user.permissions.canRegisterOisterInviteInterest;

  return (
    <div className="p-4 border-[0.5px] border-gray-300 rounded-md">
      <h3 className="font-bold text-lg my-2">Private Market Opportunity</h3>
      <p className="mb-4">
        Would you be interested in an exclusive Private Market Opportunity where you get a chance to
        invest in a research-backed basket of high-growth private companies across sectors, at a
        discounted entry price? Please click the button below to register interest.
      </p>
      {!canRegisterInterest && (
        <p className="text-sm text-indigo-800 mb-4">
          Thank you for registering your interest. We will reach out to you shortly.
        </p>
      )}
      <Button
        disabled={!canRegisterInterest}
        loading={mutationLoading}
        onClick={() => registerOisterInviteInterest()}
      >
        {canRegisterInterest ? 'Confirm Participation' : 'Interest Registered'}
      </Button>
    </div>
  );
};

export default OptInToOisterInvites;
