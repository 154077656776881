import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import DocumentLayout from 'layouts/DocumentLayout';

import ErrorMessage from 'components/ErrorMessage';

import Divider from 'primitives/Divider';
import LoadingIndicator from 'primitives/LoadingIndicator';

import alIndiaHand from 'media/alIndiaHand.svg';
import alIndiaLogo from 'media/alIndiaLogoGrey.png';

import FundDistributions from './FundDistributions';
import FundInvestments from './FundInvestments';
import { InvestorInformation } from './InvestorInformation';
import SyndicateDistributions from './SyndicateDistributions';
import SyndicateInvestments from './SyndicateInvestments';

const INVESTMENTS_QUERY = gql(`
  query fetchUnitStatement($id: ID!) {
    investmentEntity(id: $id) {
      id
      name
      user {
        id
        phoneNumber
        email
      }
      kyc {
        id
        address {
          id
          lineOne
          lineTwo
          city
          state
          country
          pincode
        }
      }
      oldPlatformDistributions {
        id
        proceedsFromExit
        distributedAmount
        deal {
          id
          schemeName
        }
      }
      oldPlatformFundDistributions {
        id
      }
    }
  }
`);

const CoverImage: FC<{ valuationDate: string }> = ({ valuationDate }) => {
  return (
    <div
      className="bg-indigo-700 rounded-xl mb-5 relative bg-no-repeat bg-[length:500px_500px] bg-[120%_5rem]"
      style={{ backgroundImage: `url(${alIndiaHand})` }}
    >
      <div className="min-w-0 flex-1 absolute left-4 top-4 bg-white p-1 rounded-md">
        <img className="h-4 w-auto" src={alIndiaLogo} alt="Your Company" />
      </div>
      <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Investor Unit
            <br />
            Holding Statement
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200">
            {dayjs(valuationDate).format('D MMMM YYYY')}
          </p>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div>
      <div className="max-w-3xl mt-4 px-4">
        <ul className="list-decimal">
          <li className="text-xs text-gray-500">
            This is a system generated report, please bring any discrepancy/error to our attention
            by writing to us at india@angellist.com
          </li>
          <li className="text-xs text-gray-500">
            The estimated Fair Market Value (FMV) of an investment is based on the price at which a
            company issues securities in its most recent equity round.
          </li>
          <li className="text-xs text-gray-500">
            Scheme terms and conditions are as per the contribution agreement and the deal note
            shared at the time of making investment.
          </li>
          <li className="text-xs text-gray-500">
            FMV is before allocating carry to the Syndicate Lead and the Investment Manager
          </li>
          <li className="text-xs text-gray-500">
            The balance amount (if any) from your fund investments has been carried forward and will
            reflect in future statements.
          </li>
        </ul>
      </div>

      <Divider />
      <div className="text-gray-600 text-sm text-center">
        <h2 className="text-gray-800 text-lg font-bold">AngelList India LLP</h2>
        <p className="font-semibold">SEBI Registration No: IN/AIF1/17-18/0504</p>
        <p className="font-semibold">GST Registration No: 07AAGTA1571Q1ZC</p>
        <p>Mailing Address - C/o Vistra Corporate Services (India) Private Limited</p>
        <p>No. 52, 1st Floor, 100 Feet Road 2nd Block, Koramangala, Bangalore 560034, India</p>
        <p>TEL. NO.: +91 80-2563-0686</p>
      </div>
    </div>
  );
};

const UnitStatement: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const valuationDate = '2024-09-30';

  const { loading, error, data, refetch } = useQuery(INVESTMENTS_QUERY, {
    variables: {
      limit: 1000,
      id: investmentEntityId,
    },
    notifyOnNetworkStatusChange: true,
  });

  function renderContent() {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const investmentEntity = data.investmentEntity;

    return (
      <>
        <CoverImage valuationDate={valuationDate} />
        <InvestorInformation investmentEntity={investmentEntity} />
        <SyndicateInvestments
          investmentEntityId={investmentEntityId}
          valuationDate={valuationDate}
        />
        <SyndicateDistributions
          investmentEntityId={investmentEntityId}
          valuationDate={valuationDate}
        />
        <FundInvestments investmentEntityId={investmentEntityId} valuationDate={valuationDate} />
        <FundDistributions investmentEntityId={investmentEntityId} valuationDate={valuationDate} />
        <Footer />
      </>
    );
  }

  return <DocumentLayout>{renderContent()}</DocumentLayout>;
};

export default UnitStatement;
