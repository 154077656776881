import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';

import { INDIAN_STATES } from 'utils/states';

interface InvestorInformationProps {
  investmentEntity: {
    name: string;
    kyc?: {
      address?: {
        lineOne?: string | null;
        lineTwo?: string | null;
        city?: string | null;
        state?: string | null;
        pincode?: string | null;
      } | null;
    } | null;
    user: {
      phoneNumber?: string | null;
      email: string;
    };
  };
}

export const InvestorInformation = ({ investmentEntity }: InvestorInformationProps) => {
  return (
    <dl className="my-5 space-y-4 text-base leading-7 text-gray-600 p-4 bg-white rounded-xl border border-gray-200">
      <p className="text-gray-900 text-xl font-bold">{investmentEntity.name}</p>
      <div className="flex gap-x-4">
        <dt className="flex-none">
          <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
        </dt>
        <dd>
          {investmentEntity.kyc?.address?.lineOne}, {investmentEntity.kyc?.address?.lineTwo}
          <br />
          {investmentEntity.kyc?.address?.city},{' '}
          {INDIAN_STATES[investmentEntity.kyc?.address?.state || '']}{' '}
          {investmentEntity.kyc?.address?.pincode}
        </dd>
      </div>
      <div className="flex gap-x-4">
        <dt className="flex-none">
          <span className="sr-only">Telephone</span>
          <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
        </dt>
        <dd>
          <a className="hover:text-gray-900" href={`tel:${investmentEntity.user.phoneNumber}`}>
            {investmentEntity.user.phoneNumber}
          </a>
        </dd>
      </div>
      <div className="flex gap-x-4">
        <dt className="flex-none">
          <span className="sr-only">Email</span>
          <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
        </dt>
        <dd>
          <a className="hover:text-gray-900" href={`mailto:${investmentEntity.user.email}`}>
            {investmentEntity.user.email}
          </a>
        </dd>
      </div>
    </dl>
  );
};
