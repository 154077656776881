import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';

import constants from 'utils/constants';

import GetStartedBanner from './GetStartedBanner';
import UnderReviewBanner from './UnderReviewBanner';

const PORTFOLIO_QUERY = gql(`
  query SyndicatePortfolio($id: ID!) {
    syndicate(id: $id) {
      id
      name
      tagline
      image
      status
      statistics {
        topIndustry
        topStage
        totalRaisedAmount
        totalUnrealizedValue
        totalRealizedValue
        multipleOnInvestment
      }
    }
  }
`);

function SyndicateTitle({
  name,
  tagline,
  image,
}: {
  name: string;
  tagline: string;
  image?: string | null;
}) {
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 rounded-xl py-3 px-2 bg-gray-50">
      <div className="flex items-start space-x-3">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-10 w-10 rounded-xl object-cover"
              src={image ?? constants.ALT_AVATAR_URL}
              alt=""
            />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
        <div className="">
          <h2 className="-mt-1 font-bold leading-7 text-gray-700 truncate text-xl tracking-tight">
            {name}
          </h2>
          <p className="text-sm text-gray-600">{tagline}</p>
        </div>
      </div>
    </div>
  );
}

const SyndicatePortfolio: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch } = useQuery(PORTFOLIO_QUERY, {
    variables: {
      id: syndicateId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    if (data.syndicate.status === SyndicateStatusEnumType.UnderReview)
      return (
        <>
          <SyndicateTitle
            name={data.syndicate.name}
            tagline={data.syndicate.tagline}
            image={data.syndicate.image}
          />
          <UnderReviewBanner />
        </>
      );

    if (
      !data.syndicate.statistics.totalRaisedAmount ||
      data.syndicate.statistics.totalRaisedAmount === 0
    ) {
      return (
        <>
          <SyndicateTitle
            name={data.syndicate.name}
            tagline={data.syndicate.tagline}
            image={data.syndicate.image}
          />
          <GetStartedBanner syndicateId={syndicateId} />
        </>
      );
    }

    return (
      <>
        <SyndicateTitle
          name={data.syndicate.name}
          tagline={data.syndicate.tagline}
          image={data.syndicate.image}
        />
        <Statistics
          statistics={[
            {
              label: 'Total Raised Amount',
              value: data.syndicate.statistics.totalRaisedAmount,
              type: 'CURRENCY',
            },
            {
              label: 'Total Unrealized Value',
              value: data.syndicate.statistics.totalUnrealizedValue,
              type: 'CURRENCY',
            },
            // TODO: Add this back when exits are taken care of
            // {
            //   label: 'Total Realized Value',
            //   value: '-',
            //   // type: 'CURRENCY',
            // },
            {
              label: 'Multiple on Investment',
              value: data.syndicate.statistics.multipleOnInvestment,
              type: 'MULTIPLE',
            },
          ]}
        />
        {/* TODO: Bring this back once all the valuation history fixes are done */}
        {/* <SyndicatePortfolioChart syndicateId={syndicateId} /> */}
        <Statistics
          statistics={[
            {
              label: 'Top Industry',
              value: data.syndicate.statistics.topIndustry,
              type: 'INDUSTRY',
            },
            {
              label: 'Top Stage',
              value: data.syndicate.statistics.topStage,
              type: 'STAGE',
            },
          ]}
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Syndicate Portfolio"
        subTitle="Track your syndicate’s investments and performance."
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default SyndicatePortfolio;
