import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { formatCurrency } from 'utils/format-helper';

const FUND_CLOSINGS_QUERY = gql(`
  query fetchFundClosingsForUnitStatement($id: ID!, $cursor: ID, $limit: Int) {
    investmentEntity(id: $id) {
      id
      fundClosings(filters: { statuses: [ACTIVE] }, cursor: $cursor, limit: $limit) {
        nodes {
          id
          fund {
            id
            name
          }
          setupFee
          placementFee
          amount
          wiredAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FUND_INVESTMENTS_QUERY = gql(`
  query fetchFundInvestmentsForFundClosing($id: ID!, $valuationDate: String) {
    fundClosing(id: $id) {
      id
      investments(filters: { statuses: [ACTIVE], forUnitStatement: true }, limit: 1000) {
        nodes {
          id
          deal {
            id
            schemeName
            companyInformation {
              company {
                id
                image
                name
              }
            }
            footnotesOnUnitStatement
          }
          numberOfUnits
          amount
          statistics(valuationDate: $valuationDate) {
            unrealisedValue
          }
        }
      }
    }
  }
`);

const FundClosings: FC<{
  fundName: string;
  investedAmount: number;
  setupFee: number;
  placementFee?: number | null;
  investmentDate: string;
  fundClosingId: string;
  valuationDate: string;
}> = ({
  fundName,
  investedAmount,
  setupFee,
  placementFee,
  investmentDate,
  fundClosingId,
  valuationDate,
}) => {
  const { loading, error, data, refetch } = useQuery(FUND_INVESTMENTS_QUERY, {
    variables: {
      id: fundClosingId,
      valuationDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fundInvestments = data.fundClosing.investments.nodes;

  if (!fundInvestments.length) return null;

  const footnoteSymbols = [
    '*',
    '†',
    '‡',
    '§',
    '¶',
    '‖',
    '**',
    '††',
    '‡‡',
    '§§',
    '¶¶',
    '‖‖',
    '***',
    '†††',
    '‡‡‡',
    '§§§',
    '¶¶¶',
    '‖‖‖',
    '****',
    '††††',
    '‡‡‡‡',
    '§§§§',
  ];

  // Add footnote numbers to scheme names
  const footnotedFundInvestments = fundInvestments.filter(
    fundInvestment => fundInvestment.deal.footnotesOnUnitStatement
  );
  const fundInvestmentsWithFootnotes = fundInvestments.map(fundInvestment => {
    if (!fundInvestment.deal.footnotesOnUnitStatement) return fundInvestment;

    const footnoteIndex = footnotedFundInvestments.findIndex(c => c.id === fundInvestment.id);
    return {
      ...fundInvestment,
      deal: {
        ...fundInvestment.deal,
        schemeName: `${fundInvestment.deal.schemeName} [${
          footnoteSymbols[footnoteIndex % footnoteSymbols.length]
        }]`,
      },
    };
  });

  function stat(label, value) {
    return (
      <div key={label} className="p-3">
        <dt className="text-xs font-normal text-gray-900">{label}</dt>
        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-sm font-medium text-indigo-600">{value}</div>
        </dd>
      </div>
    );
  }

  return (
    <div className="mb-16 mx-auto max-w-4xl">
      <dl className="my-3 flex gap-5 bg-white rounded-lg shadow-sm ring-1 ring-gray-200">
        {stat('Fund Name', fundName)}
        {stat('Invested Amount', formatCurrency(investedAmount))}
        {stat('Setup Fee', formatCurrency(setupFee))}
        {placementFee && stat('Placement Fee', formatCurrency(placementFee))}
        {stat('Investment Date', investmentDate)}
      </dl>
      <DataTable
        data={fundInvestmentsWithFootnotes}
        columns={[
          {
            label: 'Investee (DBA)',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Scheme Name',
            fieldName: 'deal.schemeName',
          },
          {
            label: 'No. of Units',
            fieldName: 'numberOfUnits',
            type: 'NUMBER',
          },
          {
            label: 'Investment',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Estimated FMV',
            fieldName: 'statistics.unrealisedValue',
            type: 'CURRENCY',
          },
        ]}
      />
      {fundInvestmentsWithFootnotes.some(
        fundInvestment => fundInvestment?.deal?.footnotesOnUnitStatement
      ) && (
        <div className="mt-4 max-w-5xl">
          <ul className="text-gray-600 text-xs mb-4">
            {footnotedFundInvestments.map((fundInvestment, index) => (
              <li key={fundInvestment?.deal?.id}>
                [{footnoteSymbols[index % footnoteSymbols.length]}]{' '}
                {fundInvestment?.deal?.footnotesOnUnitStatement}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const FundInvestments: FC<{
  investmentEntityId: string;
  valuationDate: string;
}> = ({ investmentEntityId, valuationDate }) => {
  const { loading, error, data, refetch } = useQuery(FUND_CLOSINGS_QUERY, {
    variables: {
      limit: 10,
      id: investmentEntityId,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fundClosings = data.investmentEntity.fundClosings.nodes;

  if (!fundClosings.length) return null;

  return (
    <>
      <h2 className="mt-8 text-xl font-bold text-center">Fund Investments</h2>
      <p className="text-gray-600 text-sm mb-4 text-center">
        As of {dayjs(valuationDate).format('D MMMM YYYY')}
      </p>
      {fundClosings.map(fundClosing => (
        <FundClosings
          fundName={fundClosing.fund.name}
          investedAmount={fundClosing.amount}
          setupFee={fundClosing.setupFee}
          placementFee={fundClosing.placementFee}
          investmentDate={dayjs(fundClosing.wiredAt).format('DD MMMM YYYY')}
          fundClosingId={fundClosing.id}
          valuationDate={valuationDate}
        />
      ))}
    </>
  );
};

export default FundInvestments;
