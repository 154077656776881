import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import LoadingIndicator from 'primitives/LoadingIndicator';

import UpdateInvestmentEntity from './UpdateInvestmentEntity';

const INVESTMENT_ENTITY_QUERY = gql(`
  query InvestmentEntityInformation($id: ID!) {
    investmentEntity(id: $id) {
      id
      name
      type
      status
      createdAt
      updatedAt
      statistics {
        totalInvestedAmount
        totalRealizedValue
        totalUnrealizedValue
      }
    }
  }
`);

const InvestmentEntityInformation: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntity = data.investmentEntity;

  return (
    <>
      <Statistics
        statistics={[
          {
            label: 'Total Amount Invested',
            value: investmentEntity.statistics.totalInvestedAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Total Realized Value',
            value: investmentEntity.statistics.totalRealizedValue,
            type: 'CURRENCY',
          },
          {
            label: 'Total Unrealized Value',
            value: investmentEntity.statistics.totalUnrealizedValue,
            type: 'CURRENCY',
          },
        ]}
      />
      {/* TODO: Bring this back once all the valuation history fixes are done */}
      {/* <PortfolioChart key={investmentEntity.id} investmentEntityId={investmentEntity.id} /> */}
      <UpdateInvestmentEntity
        id={investmentEntity.id}
        name={investmentEntity.name}
        type={investmentEntity.type}
      />
    </>
  );
};

export default InvestmentEntityInformation;
