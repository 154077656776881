import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { PostStatusEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import constants from 'utils/constants';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import PublishPostDialogButton from '../SyndicateDeals/Deal/Posts/PublishPostDialogButton';

const GET_POST_QUERY = gql(`
  query GetPostInSyndicateDashboard($id: ID!) {
    post(id: $id) {
      id
      status
      title
      message
      attachments {
        url
        name
      }
      sender {
        name
      }
      syndicate {
        id
        name
        image
      }
      deal {
        id
      }
      targetClosingStatuses
      createdAt
      publishedAt
    }
  }
`);

const UPDATE_POST_MUTATION = gql(`
  mutation UpdatePostOnSyndicate($id: ID!, $title: String!, $message: String!) {
    updatePost(id: $id, title: $title, message: $message) {
      id
      status
      title
      message
      deal {
        id
        companyInformation {
          id
          company {
            name
          }
        }
      }
      createdAt
    }
  }
`);

const REMOVE_POST = gql(`
  mutation RemovePostFromDeal($id: ID!) {
    removePost(id: $id) {
      id
      status
    }
  }
`);

const PostSlideOver = ({
  open = false,
  postId,
  onClose,
}: {
  open: boolean;
  postId: string;
  onClose: () => void;
}) => {
  const { data, error, loading } = useQuery(GET_POST_QUERY, {
    variables: {
      id: postId,
    },
    skip: !open,
  });

  const [updatePost, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_POST_MUTATION,
    {
      refetchQueries: ['GetPostInSyndicateDashboard', 'GetPostForPublish'],
    }
  );

  const [removePost, { loading: removeLoading, error: removeError }] = useMutation(REMOVE_POST, {
    update(cache) {
      cache.modify({
        id: cache.identify({
          __typename: 'SyndicateType',
          id: data?.post.syndicate.id,
        }),
        fields: {
          posts(existingPostsRef, { readField }) {
            return {
              ...existingPostsRef,
              nodes: existingPostsRef.nodes.filter(
                (postRef: any) => postId !== readField('id', postRef)
              ),
            };
          },
        },
      });
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} />;

    const post = data.post;

    const { label, color } = statusEnumToReadable(post.status);

    return (
      <>
        <div className="flex flex-col bg-gray-100 rounded-lg">
          <div className="flex flex-row p-2 justify-between items-center">
            <div className="flex flex-row p-2 space-x-4">
              <img
                src={post.syndicate.image ?? constants.ALT_AVATAR_URL}
                style={{
                  width: '50px',
                  height: '50px',
                  border: '0.5px solid #aaa',
                  borderRadius: '1000px',
                }}
                alt="Logo"
              />
              <div>
                <p className="font-bold">{post.syndicate.name}</p>
                <p className="text-sm">{post.sender.name}</p>
              </div>
            </div>
          </div>
        </div>
        <Badge label={label} color={color} />
        <p className="text-sm text-gray-500 !mt-1">
          {post.status === PostStatusEnumType.Published && post.publishedAt ? (
            <>
              Published at {dayjs(post.publishedAt).format('MMMM DD, YYYY hh:mm A')}
              <br />
            </>
          ) : (
            <>
              Created at {dayjs(post.createdAt).format('MMMM DD, YYYY hh:mm A')}
              <br />
            </>
          )}
        </p>
        <FormPanelWithReadMode
          loading={updateLoading}
          error={updateError}
          onSubmit={data => {
            updatePost({
              variables: {
                id: postId,
                title: data.title,
                message: data.content,
              },
            });
          }}
          submitButtonLabel="Save"
          disableEdit={post.status !== PostStatusEnumType.Draft}
        >
          <FormInput
            fullWidth
            type="text"
            fieldName="title"
            label="Title"
            defaultValue={post.title}
            validators={{
              required: true,
            }}
          />
          <FormInput
            fullWidth
            type="custom"
            fieldName="content"
            label="Content"
            defaultValue={post.message}
            customInput={EditorFormInput}
            validators={{
              required: true,
            }}
          />
        </FormPanelWithReadMode>
        {post.attachments && post.attachments.length > 0 && (
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-900">Attachments</h3>
            <ul className="mt-2 divide-y divide-gray-100 rounded-md border border-gray-200">
              {post.attachments.map(attachment => (
                <li key={attachment.url} className="flex items-center justify-between py-2 px-4">
                  <span className="truncate text-sm text-gray-500">{attachment.name}</span>
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-blue-600 hover:text-blue-500"
                  >
                    View
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {removeError && <ErrorMessage error={removeError} />}
        {post.status === PostStatusEnumType.Draft && (
          <div className="flex gap-x-2">
            <PublishPostDialogButton id={postId} />
            <Button
              loading={removeLoading}
              variant="secondary"
              className="!text-red-500"
              onClick={() => {
                removePost({ variables: { id: postId } }).then(() => onClose());
              }}
            >
              Remove
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <SlideOver open={open} onClose={onClose}>
      {renderContent()}
    </SlideOver>
  );
};

export default PostSlideOver;
