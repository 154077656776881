import { FC } from 'react';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import InvestmentWishlist from 'components/InvestmentWishlist';

const WishlistPage: FC = () => {
  const renderContent = () => {
    return (
      <>
        <SidebarLayoutNavigation title="Wishlist" subTitle="Build your investing wishlist" />
        <SidebarLayoutContent>
          <InvestmentWishlist size="wide" />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default WishlistPage;
