import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FormInput, FormPanel } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import theme from './theme.module.scss';

const CREATE_POST_MUTATION = gql(`
  mutation CreatePost($title: String!, $message: String!, $syndicateId: ID!, $dealId: ID, $attachments: [Upload]) {
    createPost(title: $title, message: $message, syndicateId: $syndicateId, dealId: $dealId, attachments: $attachments) {
      id
      status
      title
      message
      createdAt
      publishedAt
      attachments {
        url
        name
      }
      deal {
        companyInformation {
          company {
            name
          }
        }
      }
      sender {
        id
        name
      }
      syndicate {
        id
        name
        image
      }
    }
  }
`);

const CreatePost: FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const [createPost, { loading, error }] = useMutation(CREATE_POST_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          __typename: 'SyndicateType',
          id: syndicateId,
        }),
        fields: {
          posts(existingPostsRef) {
            const newPostNodeRef = cache.writeFragment({
              data: data.createPost,
              fragment: gql(`
                fragment NewPost on PostType {
                  id
                  status
                  title
                  message
                  createdAt
                  publishedAt
                  deal {
                    companyInformation {
                      company {
                        name
                      }
                    }
                  }
                  sender {
                    id
                    name
                  }
                  syndicate {
                    id
                    name
                    image
                  }
                }
              `),
            });

            const updatedPostsRef = {
              nodes: [newPostNodeRef, ...existingPostsRef.nodes],
              pageInfo: { ...existingPostsRef.pageInfo, cursor: data.createPost?.id },
            };

            return updatedPostsRef;
          },
        },
      });
    },
  });

  return (
    <div className={theme.postContainer}>
      <div className="flex justify-end">
        <Button onClick={() => setOpen(true)}>Create Post</Button>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel className="sm:!w-screen sm:!max-w-3xl">
          <DialogTitle>Create a Post</DialogTitle>
          <DialogSubTitle>Send a message to all the LPs in your network</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data => {
              createPost({
                variables: {
                  syndicateId,
                  title: data.title,
                  message: data.content,
                  attachments: data.files ? data.files : undefined,
                },
              }).then(() => {
                setOpen(false);
              });
            }}
            onCancel={() => setOpen(false)}
            buttonRef={buttonRef}
          >
            <FormInput
              fullWidth
              type="text"
              fieldName="title"
              label="Title"
              defaultValue={''}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="custom"
              fieldName="content"
              label="Content"
              defaultValue={''}
              customInput={EditorFormInput}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fullWidth
              type="upload"
              fieldName="files"
              label="Attachments"
              defaultValue={''}
            />
          </FormPanel>
          <DialogActions className="sm:grid-cols-2">
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Save Draft
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </div>
  );
};

export default CreatePost;
