import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { formatCurrency } from 'utils/format-helper';

const FUND_CLOSINGS_QUERY = gql(`
  query fetchFundClosingsForUnitStatementDistributions($id: ID!, $cursor: ID, $limit: Int) {
    investmentEntity(id: $id) {
      id
      fundClosings(filters: { statuses: [ACTIVE] }, cursor: $cursor, limit: $limit) {
        nodes {
          id
          fund {
            id
            name
          }
          setupFee
          placementFee
          amount
          wiredAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const FUND_DISTRIBUTIONS_QUERY = gql(`
  query fetchFundDistributionsForFundClosing($id: ID!) {
    fundClosing(id: $id) {
      id
      fundDistributions(forUnitStatement: true) {
        id
        deal {
          id
          schemeName
          companyInformation {
            company {
              id
              image
              name
            }
          }
          finalisedAt
        }
        investedAmount
        proceedsFromExit
        distributedAmount
        dealExit {
          id
          footnotes
        }
      }
      oldPlatformFundDistributions {
        id
        investedAmount
        proceedsFromExit
        distributedAmount
        deal {
          id
          schemeName
          companyInformation {
            company {
              id
              image
              name
            }
          }
        }
        dealExit {
          id
          footnotes
        }
      }
    }
  }
`);

const FundClosings: FC<{
  fundName: string;
  investedAmount: number;
  setupFee: number;
  placementFee?: number | null;
  investmentDate: string;
  fundClosingId: string;
  valuationDate: string;
}> = ({
  fundName,
  investedAmount,
  setupFee,
  placementFee,
  investmentDate,
  fundClosingId,
  valuationDate,
}) => {
  const { loading, error, data, refetch } = useQuery(FUND_DISTRIBUTIONS_QUERY, {
    variables: {
      id: fundClosingId,
      valuationDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fundDistributions = data.fundClosing.fundDistributions;
  const oldPlatformFundDistributions = data.fundClosing.oldPlatformFundDistributions;

  if (!fundDistributions.length && !oldPlatformFundDistributions.length) return null;

  const footnoteSymbols = [
    '*',
    '†',
    '‡',
    '§',
    '¶',
    '‖',
    '**',
    '††',
    '‡‡',
    '§§',
    '¶¶',
    '‖‖',
    '***',
    '†††',
    '‡‡‡',
    '§§§',
    '¶¶¶',
    '‖‖‖',
    '****',
    '††††',
    '‡‡‡‡',
    '§§§§',
  ];

  const combinedDistributions = [...fundDistributions, ...oldPlatformFundDistributions];

  const footnotedDistributions = combinedDistributions.filter(
    distribution => distribution.dealExit?.footnotes
  );
  const distributionsWithFootnotes = combinedDistributions.map(distribution => {
    if (!distribution.dealExit?.footnotes) return distribution;

    const footnoteIndex = footnotedDistributions.findIndex(d => d.id === distribution.id);
    return {
      ...distribution,
      deal: {
        ...distribution.deal,
        schemeName: `${distribution.deal.schemeName} [${
          footnoteSymbols[footnoteIndex % footnoteSymbols.length]
        }]`,
      },
    };
  });

  function stat(label, value) {
    return (
      <div key={label} className="p-3">
        <dt className="text-xs font-normal text-gray-900">{label}</dt>
        <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
          <div className="flex items-baseline text-sm font-medium text-indigo-600">{value}</div>
        </dd>
      </div>
    );
  }

  return (
    <div className="mb-16 mx-auto max-w-4xl">
      <dl className="my-3 flex gap-5 bg-white rounded-lg shadow-sm ring-1 ring-gray-200">
        {stat('Fund Name', fundName)}
        {stat('Invested Amount', formatCurrency(investedAmount))}
        {stat('Setup Fee', formatCurrency(setupFee))}
        {placementFee && stat('Placement Fee', formatCurrency(placementFee))}
        {stat('Investment Date', investmentDate)}
      </dl>
      <DataTable
        data={distributionsWithFootnotes}
        columns={[
          {
            label: 'Investee (DBA)',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Scheme Name',
            fieldName: 'deal.schemeName',
          },
          {
            label: 'Investment',
            fieldName: 'investedAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Proceeds after carry earned/paid',
            fieldName: 'proceedsFromExit',
            type: 'CURRENCY',
          },
          {
            label: 'Amount distributable after TDS',
            fieldName: 'distributedAmount',
            type: 'CURRENCY',
          },
        ]}
      />
      {footnotedDistributions.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-bold">Notes</h3>
          <ul className="text-gray-600 text-xs mb-4">
            {footnotedDistributions.map((distribution, index) => (
              <li key={distribution.dealExit?.id}>
                [{footnoteSymbols[index % footnoteSymbols.length]}]{' '}
                {distribution.dealExit?.footnotes}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const FundDistributions: FC<{
  investmentEntityId: string;
  valuationDate: string;
}> = ({ investmentEntityId, valuationDate }) => {
  const { loading, error, data, refetch } = useQuery(FUND_CLOSINGS_QUERY, {
    variables: {
      limit: 10,
      id: investmentEntityId,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fundClosings = data.investmentEntity.fundClosings.nodes;

  if (!fundClosings.length) return null;

  return (
    <>
      <h2 className="mt-8 text-xl font-bold text-center">Exited Fund Investments</h2>
      <p className="text-gray-600 text-sm mb-4 text-center">
        As of {dayjs(valuationDate).format('D MMMM YYYY')}
      </p>
      {fundClosings.map(fundClosing => (
        <FundClosings
          fundName={fundClosing.fund.name}
          investedAmount={fundClosing.amount}
          setupFee={fundClosing.setupFee}
          placementFee={fundClosing.placementFee}
          investmentDate={dayjs(fundClosing.wiredAt).format('DD MMMM YYYY')}
          fundClosingId={fundClosing.id}
          valuationDate={valuationDate}
        />
      ))}
    </>
  );
};

export default FundDistributions;
