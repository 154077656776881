import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterBar from 'components/FilterBar';

import LoadingIndicator from 'primitives/LoadingIndicator';

const CLOSINGS_QUERY = gql(`
  query getCompletedCommitmentsOfUser(
    $id: ID!
    $cursor: ID
    $limit: Int
    $filters: DashboardClosingsFilterType
  ) {
    investmentEntity(id: $id) {
      id
      statistics {
        totalInvestedAmount
        totalRealizedValue
        totalUnrealizedValue
      }
    }
    dashboard {
      id
      closingsStatisticsBreakdownByStatus(
        statuses: [ACTIVE, COMMITTED, TRANSFERRED, EXITED]
        investmentEntityId: $id
      ) {
        status
        numberOfClosings
      }
      closings(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          deal {
            id
            companyInformation {
              id
              company {
                id
                image
                name
              }
            }
          }
          investmentEntity {
            id
            name
          }
          amount
          status
          wiredAt
          statistics {
            id
            unrealisedValue
            realisedValue
            multipleOnInvestment
          }
        }
        pageInfo {
          hasNextPage
          cursor
          totalCount
        }
      }
    }
  }
`);

const CommitmentsOfUser: FC<{ investmentEntityId: string }> = ({ investmentEntityId }) => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    CLOSINGS_QUERY,
    {
      variables: {
        filters: {
          statuses: [ClosingStatusEnumType.Active],
          investmentEntityId,
        },
        limit: 20,
        id: investmentEntityId,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    // If there are no active closings, refetch with committed and transferred closings
    if (data) {
      const activeClosings =
        data.dashboard.closingsStatisticsBreakdownByStatus.find(
          status => status.status === ClosingStatusEnumType.Active
        )?.numberOfClosings ?? 0;

      if (activeClosings === 0) {
        refetch({
          filters: {
            statuses: [ClosingStatusEnumType.Committed, ClosingStatusEnumType.Transferred],
            investmentEntityId,
          },
        });
      }
    }
  }, [data, investmentEntityId, refetch]);

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data.dashboard.closings.nodes;
  const pageInfo = data.dashboard.closings.pageInfo;

  const activeClosings =
    data.dashboard.closingsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Active
    )?.numberOfClosings ?? 0;

  const committedClosings =
    (data.dashboard.closingsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Committed
    )?.numberOfClosings ?? 0) +
    (data.dashboard.closingsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Transferred
    )?.numberOfClosings ?? 0);

  const exitedClosings =
    data.dashboard.closingsStatisticsBreakdownByStatus.find(
      status => status.status === ClosingStatusEnumType.Exited
    )?.numberOfClosings ?? 0;

  const filters = [
    ...(activeClosings > 0
      ? [
          {
            label: `Active (${activeClosings})`,
            value: [ClosingStatusEnumType.Active],
          },
          {
            label: `Committed (${committedClosings})`,
            value: [ClosingStatusEnumType.Committed, ClosingStatusEnumType.Transferred],
          },
        ]
      : [
          {
            label: `Committed (${committedClosings})`,
            value: [ClosingStatusEnumType.Committed, ClosingStatusEnumType.Transferred],
          },
        ]),
    ...(exitedClosings > 0
      ? [
          {
            label: `Exited (${exitedClosings})`,
            value: [ClosingStatusEnumType.Exited],
          },
        ]
      : []),
  ];

  return (
    <div>
      <FilterBar
        filters={filters}
        currentValue={variables?.filters?.statuses}
        onSelect={value => {
          refetch({
            filters: {
              investmentEntityId,
              statuses: value as ClosingStatusEnumType[],
            },
          });
        }}
      />

      {/* <div className="mt-4" /> */}

      {/* {closings.length > 1 && (
        <TextField
          placeholder="Search for a company"
          onChange={debounce(e => {
            refetch({
              filters: {
                ...variables?.filters,
                text: e.target.value,
              },
            });
          }, 500)}
        />
      )} */}

      <div className="mt-4" />

      <DataTable
        data={closings}
        onClick={closing => navigate(`/invest/deal/${closing.deal.id}`)}
        emptyListTitle="Your haven't made any investments yet"
        columns={[
          {
            label: 'Company',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Investment Entity',
            fieldName: 'investmentEntity.name',
          },
          {
            label: 'Invested',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Invest Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Active)
            ? [
                {
                  label: 'Net Value',
                  fieldName: 'statistics.unrealisedValue',
                  type: 'CURRENCY' as const,
                },
                {
                  label: 'Multiple',
                  fieldName: 'statistics.multipleOnInvestment',
                  type: 'MULTIPLE' as const,
                },
              ]
            : []),
          ...(variables?.filters?.statuses?.includes(ClosingStatusEnumType.Exited)
            ? [
                {
                  label: 'Realised Value',
                  fieldName: 'statistics.realisedValue',
                  type: 'CURRENCY' as const,
                },
              ]
            : []),
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </div>
  );
};

export default CommitmentsOfUser;
