import { useQuery } from '@apollo/client';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { PostStatusEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import constants from 'utils/constants';

const GET_POST_QUERY = gql(`
  query GetPost($id: ID!) {
    post(id: $id) {
      id
      status
      title
      message
      sender {
        name
      }
      syndicate {
        id
        name
        image
      }
      deal {
        id
        closingDate
      }
      attachments {
        url
        name
      }
      createdAt
      publishedAt
    }
  }
`);

const PostSlideOver = ({
  open = false,
  postId,
  onClose,
}: {
  open: boolean;
  postId: string;
  onClose: () => void;
}) => {
  const { data, error, loading } = useQuery(GET_POST_QUERY, {
    variables: {
      id: postId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} />;

    const post = data.post;

    return (
      <>
        <div className="flex flex-col bg-gray-100 rounded-lg">
          <div className="flex flex-row p-2 justify-between items-center">
            <div className="flex flex-row p-2 space-x-4">
              <img
                src={post.syndicate.image ?? constants.ALT_AVATAR_URL}
                style={{
                  width: '50px',
                  height: '50px',
                  border: '0.5px solid #aaa',
                  borderRadius: '1000px',
                }}
                alt="Logo"
              />
              <div>
                <p className="font-bold">{post.syndicate.name}</p>
                <p className="text-sm">{post.sender.name}</p>
              </div>
            </div>
            {/* {post.deal !== null ? (
              <Button className="h-min" onClick={() => navigate(`/invest/deal/${post.deal?.id}`)}>
                View deal
              </Button>
            ) : null} */}
          </div>
        </div>
        <p className="text-sm text-gray-500">
          {post.status === PostStatusEnumType.Published && post.publishedAt ? (
            <div className="flex items-center">
              <CheckCircleIcon aria-hidden="true" className="h-4 w-4 mr-1 text-green-500" />
              Sent at {dayjs(post.publishedAt).format('MMMM DD, YYYY hh:mm A')}
            </div>
          ) : (
            <>{dayjs(post.createdAt).format('MMMM DD, YYYY hh:mm A')}</>
          )}
        </p>
        <h2 className="text-lg font-bold">{post.title}</h2>
        <div
          className="prose text-sm prose-sm w-full"
          dangerouslySetInnerHTML={{ __html: post.message }}
        />
        {post.attachments && post.attachments.length > 0 && (
          <div className="mt-4">
            <h3 className="text-sm font-medium text-gray-900">Attachments</h3>
            <ul className="mt-2 divide-y divide-gray-100 rounded-md border border-gray-200">
              {post.attachments.map(attachment => (
                <li key={attachment.url} className="flex items-center justify-between py-2 px-4">
                  <span className="truncate text-sm text-gray-500">{attachment.name}</span>
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-medium text-blue-600 hover:text-blue-500"
                  >
                    View
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    );
  };

  return (
    <SlideOver open={open} onClose={onClose}>
      {renderContent()}
    </SlideOver>
  );
};

export default PostSlideOver;
