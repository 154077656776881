import { gql, useMutation, useQuery } from '@apollo/client';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';
import Divider from 'primitives/Divider';
import LoadingIndicator from 'primitives/LoadingIndicator';

import countries from 'utils/countries.json';
import {
  GERMAN_STATES,
  INDIAN_STATES,
  SINGAPORE_STATES,
  UNITED_KINGDOM_STATES,
  US_STATES,
} from 'utils/states';

const KYC_QUERY = gql(`
  query KycForConfirmation($id: ID!) {
    investmentEntity(id: $id) {
      id
      bankAccounts {
        id
        bankName
        accountHolderName
        accountType
        accountNumber
        ifscCode
        isVerified
      }
      kyc {
        id
        pan
        identityProofDocument {
          url
          name
        }
        addressProofDocument {
          url
          name
        }
        dateOfBirth
        address {
          id
          lineOne
          lineTwo
          city
          state
          pincode
          country
        }
      }
    }
  }
`);

const ADD_BANK_ACCOUNT_MUTATION = gql(`
    mutation AddBankAccount(
      $investmentEntityId: ID!
      $accountNumber: String!
      $accountHolderName: String!
      $bankName: String!
      $ifscCode: String!
      $accountType: BankAccountTypeEnumType!
    ) {
      addBankAccount(
        investmentEntityId: $investmentEntityId
        accountNumber: $accountNumber
        accountHolderName: $accountHolderName
        bankName: $bankName
        ifscCode: $ifscCode
        accountType: $accountType
      ) {
        id
        accountNumber
        accountHolderName
        bankName
        ifscCode
        accountType
        isVerified
      }
    }
  `);

const UPSERT_KYC_MUTATION = gql(`
    mutation UpsertKycForUnitStatement(
      $investmentEntityId: ID!
      $pan: String!
      $identityProofDocument: Upload
      $addressProofDocument: Upload
      $dateOfBirth: String!
      $lineOne: String!
      $lineTwo: String
      $city: String!
      $pincode: String!
      $state: String!
      $country: String!
    ) {
      upsertKycForUnitStatement(
        investmentEntityId: $investmentEntityId
        pan: $pan
        identityProofDocument: $identityProofDocument
        addressProofDocument: $addressProofDocument
        dateOfBirth: $dateOfBirth
        lineOne: $lineOne
        lineTwo: $lineTwo
        city: $city
        pincode: $pincode
        state: $state
        country: $country
      ) {
        id
        kyc {
          id
          pan
          identityProofDocument {
            url
            name
          }
          addressProofDocument {
            url
            name
          }
          dateOfBirth
          address {
            id
            lineOne
            lineTwo
            city
            state
            pincode
            country
          }
        }
      }
    }
  `);

const UPDATE_KYC_VERIFY_FLAG = gql(`
  mutation UpdateKycVerifyFlag($id: ID!, $verifyKyc: Boolean!) {
    updateKycVerifyFlag(id: $id, verifyKyc: $verifyKyc) {
      id
      verifyKyc
    }
  }
`);

const KycConfirmationDialogButton: FC<{
  investmentEntityId: string;
  open: boolean;
  onClose: () => void;
  documentUrl?: string;
  documentId?: string;
  setDisplayMessage?: (boolean) => void;
}> = ({ investmentEntityId, open, onClose, documentUrl, documentId, setDisplayMessage }) => {
  const [page, setPage] = useState(0);
  const [showBankAccountForm, setShowBankAccountForm] = useState(false);

  const { loading, error, data, refetch } = useQuery(KYC_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  const [addBankAccount, { loading: bankAccountMutationLoading, error: bankAccountMutationError }] =
    useMutation(ADD_BANK_ACCOUNT_MUTATION, {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: `InvestmentEntityType:${investmentEntityId}`,
          fields: {
            bankAccounts(existingBankAccounts) {
              const newAccountRef = cache.writeFragment({
                data: data.addBankAccount,
                fragment: gql(`
                fragment NewAccount on BankAccountType {
                  id
                  accountNumber
                  accountHolderName
                  bankName
                  ifscCode
                  accountType
                  isVerified
                }
              `),
              });

              return [...existingBankAccounts, newAccountRef];
            },
          },
        });
      },
    });

  const [upsertKycForUnitStatement, { loading: updateLoading, error: updateError }] =
    useMutation(UPSERT_KYC_MUTATION);

  const [updateKycVerifyFlag] = useMutation(UPDATE_KYC_VERIFY_FLAG);

  if (loading) return <LoadingIndicator />;

  if (error || !data || !investmentEntityId)
    return <ErrorMessage error={error} refetch={refetch} />;

  const eighteenYearsAgo = dayjs().subtract(18, 'year');

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogPanel className="min-w-[720px]">
        <DialogTitle>Confirm Your Personal Details</DialogTitle>
        <p>
          Ensure your details are up to date and add a bank account if you haven't already done so.
        </p>
        <div className="flex space-x-2 py-2 mt-4 mb-8">
          <div className="flex flex-col justify-center align-middle basis-1/2 space-y-1 cursor-default">
            <p className="text-sm text-center">1. KYC</p>
            <div className={classNames('h-0.5 bg-gray-200', page === 0 && 'bg-gray-500')} />
          </div>
          <div className="flex flex-col justify-center align-middle basis-1/2 space-y-1 cursor-default">
            <p className="text-sm text-center">2. Bank Account (Optional)</p>
            <div className={classNames('h-0.5 bg-gray-200', page === 1 && 'bg-gray-500')} />
          </div>
        </div>
        {page === 0 && (
          <FormPanel
            loading={updateLoading}
            error={updateError}
            onSubmit={formData => {
              if (!formData.IN && !formData.US && !formData.SG && !formData.GE && !formData.UK)
                return;
              upsertKycForUnitStatement({
                variables: {
                  investmentEntityId,
                  pan: formData.pan.toUpperCase(),
                  identityProofDocument:
                    data.investmentEntity.kyc?.identityProofDocument !==
                    formData.identityProofDocument
                      ? formData.identityProofDocument
                      : undefined,
                  addressProofDocument:
                    data.investmentEntity.kyc?.addressProofDocument !==
                    formData.addressProofDocument
                      ? formData.addressProofDocument
                      : undefined,
                  dateOfBirth: formData.dateOfBirth,
                  lineOne: formData.lineOne,
                  lineTwo: formData.lineTwo,
                  city: formData.city,
                  state: formData[formData.country],
                  pincode: formData.pincode,
                  country: formData.country,
                },
              }).then(() => {
                setPage(1);
              });
            }}
            submitButtonLabel="Confirm"
          >
            <FormInput
              fieldName="pan"
              type="text"
              fullWidth
              defaultValue={(data?.investmentEntity?.kyc?.pan || '').toUpperCase()}
              label="PAN *"
              validators={{
                required: true,
                isPAN: true,
              }}
              span="3"
            />
            <FormInput
              fieldName="dateOfBirth"
              type="date"
              fullWidth
              defaultValue={
                data.investmentEntity.kyc?.dateOfBirth
                  ? dayjs(data.investmentEntity.kyc?.dateOfBirth)
                  : ''
              }
              label="Date of Birth *"
              maxDate={eighteenYearsAgo}
              validators={{
                required: true,
              }}
              span="3"
            />
            <Divider className="col-span-full" />
            <h2 className="font-bold">Address</h2>
            <FormInput
              fieldName="country"
              type="select"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.country || ''}
              label="Country"
              options={Object.keys(countries).map(country => ({
                label: countries[country],
                value: country,
              }))}
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="lineOne"
              type="text"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.lineOne || ''}
              label="Line One *"
              validators={{
                required: true,
                minLength: 1,
              }}
            />
            <FormInput
              fieldName="lineTwo"
              type="text"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.lineTwo || ''}
              label="Line Two"
            />
            <FormInput
              fieldName="city"
              type="text"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.city || ''}
              label="City *"
              validators={{
                required: true,
                isCity: true,
              }}
            />
            <FormInput
              fieldName="IN"
              type="select"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.state || ''}
              label="State *"
              validators={{
                required: true,
              }}
              options={Object.keys(INDIAN_STATES).map(state => ({
                label: INDIAN_STATES[state],
                value: state,
              }))}
              conditionsToShow={{
                matches: [
                  {
                    field: 'country',
                    condition: '===',
                    value: 'IN',
                  },
                ],
              }}
            />
            <FormInput
              fieldName="US"
              type="select"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.state || ''}
              label="State *"
              validators={{
                required: true,
              }}
              options={Object.keys(US_STATES).map(state => ({
                label: US_STATES[state],
                value: state,
              }))}
              conditionsToShow={{
                matches: [
                  {
                    field: 'country',
                    condition: '===',
                    value: 'US',
                  },
                ],
              }}
            />
            <FormInput
              fieldName="GE"
              type="select"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.state || ''}
              label="State *"
              validators={{
                required: true,
              }}
              options={Object.keys(GERMAN_STATES).map(state => ({
                label: GERMAN_STATES[state],
                value: state,
              }))}
              conditionsToShow={{
                matches: [
                  {
                    field: 'country',
                    condition: '===',
                    value: 'GE',
                  },
                ],
              }}
            />
            <FormInput
              fieldName="SG"
              type="select"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.state || ''}
              label="State *"
              validators={{
                required: true,
              }}
              options={Object.keys(SINGAPORE_STATES).map(state => ({
                label: SINGAPORE_STATES[state],
                value: state,
              }))}
              conditionsToShow={{
                matches: [
                  {
                    field: 'country',
                    condition: '===',
                    value: 'SG',
                  },
                ],
              }}
            />
            <FormInput
              fieldName="UK"
              type="select"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.state || ''}
              label="State *"
              validators={{
                required: true,
              }}
              options={Object.keys(UNITED_KINGDOM_STATES).map(state => ({
                label: UNITED_KINGDOM_STATES[state],
                value: state,
              }))}
              conditionsToShow={{
                matches: [
                  {
                    field: 'country',
                    condition: '===',
                    value: 'UK',
                  },
                ],
              }}
            />
            <FormInput
              fieldName="pincode"
              type="text"
              fullWidth
              defaultValue={data.investmentEntity.kyc?.address?.pincode || ''}
              label="Pincode *"
              validators={{
                required: true,
                isPincode: true,
              }}
            />
            <Divider className="col-span-full" />
            <FormInput
              type="upload"
              fieldName="identityProofDocument"
              label="Upload Aadhaar Card *"
              defaultValue={data.investmentEntity.kyc?.identityProofDocument || ''}
              validators={{
                required: true,
              }}
              span="3"
              maxFileSize={10}
            />
            <FormInput
              type="upload"
              fieldName="addressProofDocument"
              label="Upload PAN Card *"
              defaultValue={data.investmentEntity.kyc?.addressProofDocument || ''}
              validators={{
                required: true,
              }}
              span="3"
              maxFileSize={10}
            />
            <div className="h-4" />
          </FormPanel>
        )}
        {page === 1 && (
          <>
            <DataTable
              data={data.investmentEntity.bankAccounts}
              emptyListTitle="Your haven't added any bank accounts yet."
              columns={[
                {
                  label: 'Bank Name',
                  fieldName: 'bankName',
                },
                {
                  label: 'Account Holder Name',
                  fieldName: 'accountHolderName',
                },
                {
                  label: 'Account Number',
                  fieldName: 'accountNumber',
                },
                {
                  label: 'IFSC Code',
                  fieldName: 'ifscCode',
                },
                {
                  label: 'Account Type',
                  fieldName: 'accountType',
                },
              ]}
            />
            <div
              className="mt-8 flex flex-row justify-between align-middle p-2 bg-gray-100 rounded-md cursor-pointer"
              onClick={() => setShowBankAccountForm(prev => !prev)}
            >
              <h3 className="text-sm font-bold self-center p-2">
                Add a New Account <span className="font-normal">(Optional)</span>
              </h3>
              {showBankAccountForm ? (
                <ChevronUpIcon className="h-5 w-5 text-gray-600 m-2" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-gray-600 m-2" />
              )}
            </div>

            {showBankAccountForm ? (
              <div className="bg-gray-100 p-4">
                <FormPanel
                  loading={bankAccountMutationLoading}
                  error={bankAccountMutationError}
                  onSubmit={data =>
                    addBankAccount({
                      variables: {
                        investmentEntityId,
                        accountNumber: data.accountNumber,
                        accountHolderName: data.accountHolderName,
                        bankName: data.bankName,
                        ifscCode: data.ifscCode,
                        accountType: data.accountType,
                      },
                    }).then(() => {
                      data.accountNumber = '';
                      data.accountHolderName = '';
                      data.bankName = '';
                      data.ifscCode = '';
                      data.accountType = '';
                    })
                  }
                  submitButtonLabel="Add Account"
                >
                  <FormInput
                    type="text"
                    fieldName="accountHolderName"
                    label="Account Holder Name"
                    defaultValue={''}
                    validators={{ required: true }}
                  />
                  <FormInput
                    type="text"
                    fieldName="accountNumber"
                    label="Account Number"
                    defaultValue={''}
                    validators={{ required: true }}
                  />
                  <FormInput
                    type="text"
                    fieldName="ifscCode"
                    label="IFSC Code"
                    defaultValue={''}
                    validators={{ required: true }}
                  />
                  <FormInput
                    type="text"
                    fieldName="bankName"
                    label="Bank Name"
                    defaultValue={''}
                    validators={{ required: true }}
                  />
                  <FormInput
                    type="radio-group"
                    fieldName="accountType"
                    label="Account Type"
                    defaultValue={''}
                    options={[
                      { label: 'Savings', value: 'SAVINGS' },
                      { label: 'Current', value: 'CURRENT' },
                    ]}
                    validators={{ required: true }}
                  />
                </FormPanel>
              </div>
            ) : (
              <></>
            )}
            <FormPanel
              loading={loading}
              error={error}
              onSubmit={() => {
                if (documentUrl && documentId) {
                  updateKycVerifyFlag({
                    variables: { id: documentId, verifyKyc: false },
                  }).then(() => {
                    window.open(documentUrl, '_blank');
                    setDisplayMessage && setDisplayMessage(true);
                    onClose();
                  });
                } else {
                  onClose();
                }
              }}
              submitButtonLabel={documentUrl ? 'View Document' : 'Close'}
              onCancel={() => setPage(0)}
              cancelButtonLabel="Back"
            >
              <div className="h-8" />
            </FormPanel>
          </>
        )}
      </DialogPanel>
    </Dialog>
  );
};

export default KycConfirmationDialogButton;
