import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const SYNDICATE_INVESTMENTS_QUERY = gql(`
    query fetchSyndicateInvestmentsForUnitStatement($id: ID!, $cursor: ID, $limit: Int, $valuationDate: String) {
      investmentEntity(id: $id) {
        id
        closings(filters: { statuses: [ACTIVE], forUnitStatement: true }, cursor: $cursor, limit: $limit) {
          nodes {
            id
            deal {
              id
              schemeName
              companyInformation {
                company {
                  id
                  image
                  name
                }
              }
              footnotesOnUnitStatement
            }
            numberOfUnits
            setupFee
            amount
            drawdownAmount
            wiredAt
            statistics(valuationDate: $valuationDate) {
              unrealisedValue
            }
          }
          pageInfo {
            hasNextPage
            cursor
          }
        }
      }
    }
  `);

const SyndicateInvestments: FC<{
  investmentEntityId: string;
  valuationDate: string;
}> = ({ investmentEntityId, valuationDate }) => {
  const { data, loading, fetchMore, error, refetch } = useQuery(SYNDICATE_INVESTMENTS_QUERY, {
    variables: { id: investmentEntityId, limit: 1000, valuationDate },
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const closings = data?.investmentEntity?.closings?.nodes || [];
  const pageInfo = data?.investmentEntity?.closings?.pageInfo || {};

  if (!closings.length) return null;

  const footnoteSymbols = [
    '*',
    '†',
    '‡',
    '§',
    '¶',
    '‖',
    '**',
    '††',
    '‡‡',
    '§§',
    '¶¶',
    '‖‖',
    '***',
    '†††',
    '‡‡‡',
    '§§§',
    '¶¶¶',
    '‖‖‖',
    '****',
    '††††',
    '‡‡‡‡',
    '§§§§',
  ];

  // Add footnote numbers to scheme names
  const footnotedClosings = closings.filter(closing => closing.deal.footnotesOnUnitStatement);
  const closingsWithFootnotes = closings.map(closing => {
    if (!closing.deal.footnotesOnUnitStatement) return closing;

    const footnoteIndex = footnotedClosings.findIndex(c => c.id === closing.id);
    return {
      ...closing,
      deal: {
        ...closing.deal,
        schemeName: `${closing.deal.schemeName} [${
          footnoteSymbols[footnoteIndex % footnoteSymbols.length]
        }]`,
      },
    };
  });

  return (
    <div>
      <h2 className="text-xl font-bold text-center">Syndicate Investments</h2>
      <p className="text-gray-600 text-sm mb-4 text-center">
        As of {dayjs(valuationDate).format('D MMMM YYYY')}
      </p>
      <DataTable
        data={closingsWithFootnotes}
        columns={[
          {
            label: 'Investee (DBA)',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Scheme Name',
            fieldName: 'deal.schemeName',
          },
          {
            label: 'Investment Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          {
            label: 'No. of Units',
            fieldName: 'numberOfUnits',
            type: 'NUMBER',
          },
          {
            label: 'Drawdown',
            fieldName: 'drawdownAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Investment',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Setup fee',
            fieldName: 'setupFee',
            type: 'CURRENCY',
          },
          {
            label: 'Estimated FMV',
            fieldName: 'statistics.unrealisedValue',
            type: 'CURRENCY',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              filter: { status: 'ACTIVE' },
              cursor: pageInfo.cursor,
            },
          })
        }
      />
      {closings.some(closing => closing?.deal?.footnotesOnUnitStatement) && (
        <div className="mt-4 max-w-5xl">
          <ul className="text-gray-600 text-xs mb-4">
            {footnotedClosings.map((closing, index) => (
              <li key={closing?.deal?.id}>
                [{footnoteSymbols[index % footnoteSymbols.length]}]{' '}
                {closing?.deal?.footnotesOnUnitStatement}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SyndicateInvestments;
