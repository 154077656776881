import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useEffect, useState } from 'react';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import ErrorMessage from 'components/ErrorMessage';
import ReportDataDiscrepancy from 'components/ReportDataDiscrepancy';

import Alert from 'primitives/Alert';
import LoadingIndicator from 'primitives/LoadingIndicator';

import CommitmentsOfUser from './Commitments';
import FundCommitmentsOfUser from './FundCommitments';
import GetStartedWithInvestingBanner from './GetStartedWithInvestingBanner';
import InvestmentEntitySwitchBar from './InvestmentEntitySwitchBar';
import StatisticsOfUser from './Statistics';

const DASHBOARD_QUERY = gql(`
  query InvestDashboard {
    dashboard {
      id
      user {
        id
        name
      }
      investmentEntities(filters: { hasInvestments: true }) {
        id
        name
      }
    }
  }
`);

const Home: FC = () => {
  const [investmentEntityOnDisplay, setInvestmentEntityOnDisplay] = useState<string | null>(null);
  const { loading, error, data, refetch } = useQuery(DASHBOARD_QUERY);

  useEffect(() => {
    if (data?.dashboard.investmentEntities.length) {
      const { investmentEntities } = data.dashboard;
      setInvestmentEntityOnDisplay(investmentEntities[investmentEntities.length - 1].id);
    }
  }, [data]);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const { investmentEntities } = data.dashboard;

    if (investmentEntities.length === 0) {
      return (
        <>
          <Alert
            title="No investments found"
            message="Your portfolio will start showing up here once you have made one or more investments on AngelList India"
            type="warning"
          />
          <GetStartedWithInvestingBanner />
        </>
      );
    }

    if (!investmentEntityOnDisplay) return null;

    return (
      <>
        <div
          className={
            investmentEntities.length > 1
              ? 'rounded-xl p-3 border border-gray-200 space-y-2'
              : 'space-y-4'
          }
        >
          {investmentEntities.length > 1 && (
            <InvestmentEntitySwitchBar
              currentInvestmentEntityId={investmentEntityOnDisplay}
              investmentEntities={investmentEntities}
              onSelect={investmentEntityId => setInvestmentEntityOnDisplay(investmentEntityId)}
            />
          )}
          <StatisticsOfUser investmentEntityId={investmentEntityOnDisplay} />
          {/* TODO: Bring this back once all the valuation history fixes are done */}
          {/* <PortfolioChart investmentEntityId={investmentEntityOnDisplay} /> */}
        </div>
        <CommitmentsOfUser investmentEntityId={investmentEntityOnDisplay} />
        <FundCommitmentsOfUser />
        <Alert
          title="Note for US investors"
          message="This platform is dedicated to supporting your investments with AngelList India. For US investments and related data please visit venture.angellist.com. These are now two separate platforms."
          type="info"
          buttonText="Visit AngelList US"
          onClick={() => window.open('https://venture.angellist.com', '_blank')}
        />
        <ReportDataDiscrepancy />
        <div className="max-w-3xl mb-32">
          <div className="space-y-4">
            <p className="text-xs text-gray-500">
              Unrealised value is the estimated value of an investment, excluding carry and fees.
            </p>
            <p className="text-xs text-gray-500">
              Realised value reflects the amount distributed or pending distribution to an investor.
            </p>
            <p className="text-xs text-gray-500">
              All multiples are calculated by dividing the unrealised value by the total invested
              amount.
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Portfolio"
        subTitle="Track your investments and monitor performance"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default Home;
