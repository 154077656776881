import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from 'primitives/LoadingIndicator';

const SYNDICATE_DISTRIBUTIONS_QUERY = gql(`
  query fetchSyndicateDistributionsForUnitStatement($id: ID!) {
    investmentEntity(id: $id) {
      id
      distributions(forUnitStatement: true) {
        id
        deal {
          id
          schemeName
          companyInformation {
            company {
              id
              image
              name
            }
          }
          finalisedAt
        }
        investedAmount
        proceedsFromExit
        distributedAmount
        dealExit {
          id
          footnotes
        }
      }
      oldPlatformDistributions {
        id
        deal {
          id
          schemeName
          companyInformation {
            company {
              id
              image
              name
            }
          }
          finalisedAt
        }
        investedAmount
        proceedsFromExit
        distributedAmount
        dealExit {
          id
          footnotes
        }
      }
    }
  }
`);

const SyndicateDistributions: FC<{
  investmentEntityId: string;
  valuationDate: string;
}> = ({ investmentEntityId, valuationDate }) => {
  const { data, loading, error, refetch } = useQuery(SYNDICATE_DISTRIBUTIONS_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const distributions = data?.investmentEntity?.distributions || [];
  const oldPlatformDistributions = data?.investmentEntity?.oldPlatformDistributions || [];

  if (!distributions.length && !oldPlatformDistributions.length) return null;

  const footnoteSymbols = [
    '*',
    '†',
    '‡',
    '§',
    '¶',
    '‖',
    '**',
    '††',
    '‡‡',
    '§§',
    '¶¶',
    '‖‖',
    '***',
    '†††',
    '‡‡‡',
    '§§§',
    '¶¶¶',
    '‖‖‖',
    '****',
    '††††',
    '‡‡‡‡',
    '§§§§',
  ];

  const combinedDistributions = [...distributions, ...oldPlatformDistributions];

  // Add footnote symbols to distributions with footnotes
  const footnotedDistributions = combinedDistributions.filter(
    distribution => distribution.dealExit?.footnotes
  );
  const distributionsWithFootnotes = combinedDistributions.map(distribution => {
    if (!distribution.dealExit?.footnotes) return distribution;

    const footnoteIndex = footnotedDistributions.findIndex(d => d.id === distribution.id);
    return {
      ...distribution,
      deal: {
        ...distribution.deal,
        schemeName: `${distribution.deal.schemeName} [${
          footnoteSymbols[footnoteIndex % footnoteSymbols.length]
        }]`,
      },
    };
  });

  return (
    <div className="mx-auto max-w-4xl">
      <h2 className="text-xl font-bold text-center mt-8">Exited Syndicate Investments</h2>
      <p className="text-gray-600 text-sm mb-4 text-center">
        As of {dayjs(valuationDate).format('D MMMM YYYY')}
      </p>
      <DataTable
        data={distributionsWithFootnotes}
        columns={[
          {
            label: 'Investee (DBA)',
            fieldName: 'deal.companyInformation.company',
            type: 'IMAGE_WITH_NAME',
          },
          {
            label: 'Scheme Name',
            fieldName: 'deal.schemeName',
          },
          {
            label: 'Investment Date',
            fieldName: 'deal.finalisedAt',
            type: 'DATE',
          },
          {
            label: 'Investment',
            fieldName: 'investedAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Proceeds after carry earned/paid',
            fieldName: 'proceedsFromExit',
            type: 'CURRENCY',
          },
          {
            label: 'Amount distributable after TDS',
            fieldName: 'distributedAmount',
            type: 'CURRENCY',
          },
        ]}
      />
      {footnotedDistributions.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-bold">Notes</h3>
          <ul className="text-gray-600 text-xs mb-4">
            {footnotedDistributions.map((distribution, index) => (
              <li key={distribution.dealExit?.id}>
                [{footnoteSymbols[index % footnoteSymbols.length]}]{' '}
                {distribution.dealExit?.footnotes}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SyndicateDistributions;
