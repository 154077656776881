import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
} from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

import ErrorMessage from 'components/ErrorMessage';

import LoadingIndicator from './LoadingIndicator';

type Option = {
  id: string;
  name: string;
} & {
  [key: string]: any;
};

export default function Autocomplete({
  options,
  value,
  onChange,
  onSearch,
  placeholder,
  loading,
  error,
  label,
}: {
  options: Option[];
  value: Option | null;
  onChange: (value: Option | null) => void;
  onSearch: (query: string) => void;
  placeholder?: string;
  loading?: boolean;
  error?: any;
  label?: string;
}) {
  return (
    <div className="">
      <Combobox
        value={value}
        onChange={value => onChange(value)}
        onClose={() => onSearch('')}
        __demoMode
      >
        {label ? (
          <Label className="block text-sm font-medium leading-6 text-gray-900 mb-1">{label}</Label>
        ) : null}
        <div className="relative">
          <ComboboxInput
            className={clsx(
              'w-full rounded-lg border-gray-300 bg-white py-1.5 pr-8 pl-3 text-sm/6 text-gray-700',
              'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
            )}
            displayValue={(option?: Option) => option?.name ?? ''}
            onChange={event => onSearch(event.target.value)}
            placeholder={placeholder}
          />
          <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
            <ChevronDownIcon className="size-4 fill-gray-500 group-data-[hover]:fill-gray-400" />
          </ComboboxButton>
        </div>

        <ComboboxOptions
          anchor="bottom"
          transition
          className={clsx(
            'w-[var(--input-width)] rounded-xl border border-gray-100 bg-white mt-1 p-1 [--anchor-gap:var(--spacing-1)] empty:invisible',
            'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 h-60 overflow-auto shadow-lg z-50'
          )}
        >
          {loading && <LoadingIndicator />}
          {error && <ErrorMessage type="alert" error={error} />}
          {options.length > 0 && !loading && !error
            ? options.map(option => (
                <ComboboxOption
                  key={option.id}
                  value={option}
                  className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-gray-100"
                >
                  <CheckIcon className="invisible size-4 fill-gray-700 group-data-[selected]:visible" />
                  <div className="text-sm/6 text-gray-700">{option.name}</div>
                </ComboboxOption>
              ))
            : null}
        </ComboboxOptions>
      </Combobox>
    </div>
  );
}
