import { gql, useMutation } from '@apollo/client';
import { FC } from 'react';

import { FormPanel } from 'components/FormPanel';

import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { getUser } from 'utils/auth';

const REMOVE_WISHLIST_COMPANY_MUTATION = gql(`
  mutation RemoveWishlistCompany($id: ID!) {
    removeInvestmentWishlistCompany(id: $id) {
      id
    }
  }
`);

const DeleteWishlistCompanyDialogButton: FC<{
  open: boolean;
  onClose: () => void;
  wishlistCompany: {
    id: string;
    name: string;
    website?: string | null;
    company?: { id: string; name: string; image: string };
  };
}> = ({ wishlistCompany, open, onClose }) => {
  const user = getUser();

  const [removeCompanyFromWishlist, { loading, error }] = useMutation(
    REMOVE_WISHLIST_COMPANY_MUTATION,
    {
      variables: {
        id: wishlistCompany.id,
      },
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: `UserType:${user.id}`,
          fields: {
            investmentWishlist(existingCompanyRefs, { readField }) {
              return existingCompanyRefs.filter(
                companyRef => wishlistCompany.id !== readField('id', companyRef)
              );
            },
          },
        });
      },
      onCompleted: () => {
        onClose();
      },
    }
  );

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogPanel>
        <DialogTitle>Remove From Wishlist</DialogTitle>
        <div className="flex flex-col items-start space-y-2">
          <div
            className={`w-24 h-24 bg-gray-300 flex items-center justify-center text-7xl font-bold text-black/70 mt-6 rounded-md`}
          >
            {wishlistCompany.company?.image ? (
              <img
                src={wishlistCompany.company?.image}
                className="w-full h-full object-contain p-2"
                alt="Logo"
              />
            ) : (
              wishlistCompany.name.charAt(0)
            )}
          </div>
          <div className="space-y-1">
            <p>{wishlistCompany.name}</p>
            {wishlistCompany.website ? (
              <a href={wishlistCompany.website} className="text-sm text-blue-700">
                {wishlistCompany.website}
              </a>
            ) : null}
          </div>
        </div>
        <p className="mt-5 text-sm">
          Are you sure? You can add this company back later if you wish to do so.
        </p>
        <FormPanel
          loading={loading}
          error={error}
          onCancel={() => onClose()}
          onSubmit={() => removeCompanyFromWishlist()}
          submitButtonLabel="Remove"
        >
          <div />
        </FormPanel>
      </DialogPanel>
    </Dialog>
  );
};

export default DeleteWishlistCompanyDialogButton;
