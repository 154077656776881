import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import { FC, useState } from 'react';

import Autocomplete from 'primitives/Autocomplete';
import LoadingIndicator from 'primitives/LoadingIndicator';

import { getUser } from 'utils/auth';
import debounce from 'utils/debounce';

import ErrorMessage from '../ErrorMessage';
import { FormPanel } from '../FormPanel';
import CreateWishlistCompanyDialogButton from './CreateWishlistCompanyDialogButton';
import DeleteWishlistCompanyDialogButton from './DeleteWishlistCompanyDialogButton';
import OptInToOisterInvites from './OptInToOisterInvites';

const USER_INVESTMENT_WISHLIST_QUERY = gql(`
  query InvestmentWishlist {
    dashboard {
      id
      user {
        id
        investmentWishlist {
          id
          name
          website
          company {
            id
            name
            image
          }
        }
      }
    }
  }
`);

const ADD_TO_INVESTMENT_WISHLIST_MUTATION = gql(`
  mutation AddInvestmentWishlistCompany($name: String!, $website: String, $companyId: ID) {
    addInvestmentWishlistCompany(name: $name, website: $website, companyId: $companyId) {
      id
      name
      website
      company {
        id
        name
      }
    }
  }
`);

type Company = {
  id: string;
  name: string;
  image?: string | null;
  website?: string | null;
};

const SEARCH_QUERY = gql(`
  query SearchCompaniesForInvestmentWishlist($text: String!, $limit: Int) {
    search(text: $text, limit: $limit) {
      companies {
        nodes {
          id
          name
          image
          website
        }
      }
    }
  }
`);

const InvestmentWishlist: FC<{
  size?: 'compact' | 'normal' | 'wide';
  variant?: 'regular' | 'flashy';
}> = ({ size = 'normal', variant = 'regular' }) => {
  const [currentValue, setCurrentValue] = useState<Company | null>(null);
  const [deleteWishlistCompany, setDeleteWishlistCompany] = useState(null);

  const { loading, error, data, refetch } = useQuery(USER_INVESTMENT_WISHLIST_QUERY);

  const [getSearchResults, { loading: searchLoading, error: searchError, data: searchData }] =
    useLazyQuery(SEARCH_QUERY);

  const user = getUser();

  const [
    addInvestmentWishlistCompany,
    { loading: addWishlistCompanyLoading, error: addWishlistCompanyError },
  ] = useMutation(ADD_TO_INVESTMENT_WISHLIST_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: `UserType:${user.id}`,
        fields: {
          investmentWishlist(existingCompanies) {
            const newCompanyRef = cache.writeFragment({
              data: data.addInvestmentWishlistCompany,
              fragment: gql(`
                fragment NewCompany on InvestmentWishlistCompanyType {
                  id
                  name
                  website
                  company {
                    id
                    name
                  }
                }
              `),
            });

            return [...existingCompanies, newCompanyRef];
          },
        },
      });
    },
  });

  if (loading) return <LoadingIndicator />;
  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentWishlist = data.dashboard.user.investmentWishlist;

  return (
    <div className="relative">
      {variant === 'flashy' ? (
        <p className="absolute -top-4 -right-4 px-4 py-2 bg-orange-400 text-xl -skew-x-6 text-white rounded-sm font-bold">
          NEW
        </p>
      ) : null}
      <div
        className={classNames(
          'border-[0.5px] rounded-md',
          size === 'compact' && 'p-4',
          size === 'normal' && 'p-4',
          size === 'wide' && 'p-12',
          variant === 'flashy' && 'border-violet-200 bg-violet-50 shadow-lg'
        )}
      >
        <h3 className="mb-2 text-xl font-bold">Build your investing wishlist.</h3>
        <h4 className="mb-8 text-base">We'll notify you if there's an investment opportunity.</h4>
        <div className="col-span-full flex flex-row min-w-[360px] space-x-4">
          <div
            className={classNames(
              'basis-3/5 grid justify-center place-items-stretch',
              size === 'wide' && 'grid-cols-4 gap-3',
              size === 'compact' && 'grid-cols-2 gap-2',
              size === 'normal' && 'grid-cols-3 gap-3'
            )}
          >
            {investmentWishlist.length > 0 ? (
              investmentWishlist.map(company => (
                <div
                  className="flex flex-col items-center cursor-pointer space-y-1 w-full h-full"
                  key={company.id}
                  onClick={() => setDeleteWishlistCompany(company)}
                >
                  <div
                    className={`${
                      [
                        'bg-red-200',
                        'bg-orange-200',
                        'bg-yellow-200',
                        'bg-green-200',
                        'bg-blue-200',
                        'bg-indigo-200',
                        'bg-purple-200',
                      ][investmentWishlist.indexOf(company) % 7]
                    } flex items-center justify-center text-7xl font-bold text-black/70 rounded-md w-full h-24`}
                    key={company.id}
                  >
                    {company.company?.image ? (
                      <img
                        src={company.company?.image}
                        className="w-full h-full object-contain p-2"
                        alt="Logo"
                      />
                    ) : (
                      company.name.charAt(0)
                    )}
                  </div>
                  <p className="text-sm text-center">{company.name}</p>
                </div>
              ))
            ) : (
              <p className="text-sm text-center h-full flex items-center justify-center col-span-full">
                No companies in your wishlist.
              </p>
            )}
            {deleteWishlistCompany ? (
              <DeleteWishlistCompanyDialogButton
                open={true}
                onClose={() => setDeleteWishlistCompany(null)}
                wishlistCompany={deleteWishlistCompany}
              />
            ) : null}
          </div>
          <div className="min-h-full w-[0.5px] bg-gray-300" />
          <div
            className={classNames(
              'min-w-32',
              size === 'compact' && 'basis-1/2',
              size === 'normal' && 'basis-2/5',
              size === 'wide' && 'basis-2/5'
            )}
          >
            <div className="space-y-8">
              <FormPanel
                loading={addWishlistCompanyLoading}
                error={addWishlistCompanyError}
                onSubmit={() => {
                  if (!currentValue) return;
                  addInvestmentWishlistCompany({
                    variables: {
                      name: currentValue.name,
                      website: currentValue.website,
                      companyId: currentValue.id,
                    },
                  }).then(() => setCurrentValue(null));
                }}
                submitButtonLabel="Add to Wishlist"
              >
                <div className={size === 'compact' ? 'w-44' : 'w-64'}>
                  <div
                    className={classNames(
                      'flex flex-row align-middle space-x-4 justify-between',
                      size === 'compact' && 'w-44',
                      size === 'normal' && 'w-64',
                      size === 'wide' && 'w-72'
                    )}
                  >
                    <Autocomplete
                      options={
                        !currentValue ? searchData?.search?.companies?.nodes || [] : [currentValue]
                      }
                      loading={searchLoading}
                      error={searchError}
                      onChange={e => {
                        setCurrentValue(e);
                      }}
                      value={currentValue}
                      onSearch={debounce(
                        e =>
                          getSearchResults({
                            variables: {
                              text: e,
                              limit: 10,
                            },
                          }),
                        500
                      )}
                      placeholder={'Search by name'}
                      label="Company Name"
                    />
                  </div>
                  <CreateWishlistCompanyDialogButton
                    onSubmit={data => {
                      addInvestmentWishlistCompany({
                        variables: {
                          name: data.name,
                          website: data.website,
                        },
                      });
                    }}
                  />
                  <div className="mb-6" />
                </div>
              </FormPanel>
              <p className="text-xs">
                This page is intended only to assess investment interest and is not an offer,
                recommendation, or advice of any kind. There is no guarantee that there will be an
                opportunity to invest in these companies.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12">
          <OptInToOisterInvites />
        </div>
      </div>
    </div>
  );
};

export default InvestmentWishlist;
